
h1, h2, h3, h4, h5, h6 {
  margin: 0;
}

h2 {
  color: #292929;
  font-weight: 600;
}

h4 {
  color: #292929;
  font-size: 14px;
  font-weight: 600;
}

h5{
  font-family: 'Playfair Display', serif;
  font-size: 24px;
  font-weight: bold;
}

h6{
  font-family: 'Playfair Display', serif;
  font-size: 18px;
  font-weight: bold;
}



a {
  color: #292929;
  text-decoration: none !important;
  -moz-transition: all 0.5s ease-in-out 0s;
  -ms-transition: all 0.5s ease-in-out 0s;
  -o-transition: all 0.5s ease-in-out 0s;
  -webkit-transition: all 0.5s ease-in-out 0s;
  transition: all 0.5s ease-in-out 0s;
}

/* Main Nav Bar Move on Hover */
a:hover {
  -moz-transition: all 0.7s ease-in-out .2s;
  -ms-transition: all 0.7s ease-in-out .2s;
  -o-transition: all 0.7s ease-in-out .2s;
  -webkit-transition: all 0.7s ease-in-out .2s;
  transition: all 0.7s ease-in-out .2s;
}

a:hover, a:focus {
  color: #CCCCCC;
  text-decoration: underline;
}

.header

.btn {

  background-color: #292929;

}

nav ul li a {
  padding: 2px 0 0 0;
  position: relative;
  display: block;
  font-size: 12px;
  color: #999999;
  letter-spacing: 0.1em;
  font-weight: 500;
  line-height: 14px;
  text-transform: uppercase;
  -moz-transition: all 0.5s ease-in-out 0s;
  -ms-transition: all 0.5s ease-in-out 0s;
  -o-transition: all 0.5s ease-in-out 0s;
  -webkit-transition: all 0.5s ease-in-out 0s;
  transition: all 0.5s ease-in-out 0s;
}

p {
  margin: 25px 0;
  padding: 0;
  font-size: 15px;
  color: #999999;
  line-height: 28px;
}

::-moz-selection {
  background: #b8a07e;
  color: #fff;
}

::selection {
  background: #b8a07e;
  color: #fff;
}

.padDiv {
  padding: 30px 20px;
}

.section-title {
  margin-bottom: 70px;
}

.section-title h2 {
  font-size: 25px;
  letter-spacing: 2px;
  text-transform: uppercase;
}

.form-control {
  background-color: transparent;
  border: 1px solid #999;
  border-radius: 0;
  color: #999999;
  font-size: 12px;
  font-weight: 500;
  height: 50px;
  letter-spacing: 0.1em;
  padding-left: 10px;
  margin: 0 0 25px 0;
  line-height: 14px;
  text-transform: uppercase;
}

.form-control:focus {
  box-shadow: none;
  outline: 0 none;
}

.paddsection {
  padding-top: 90px;
  padding-bottom: 90px;
}

.paddsections {
  padding-top: 90px;
  padding-bottom: 90px !important;
}

.hvr-shutter-in-horizontal::before {
  background: #333;
}

.hvr-shutter-in-horizontal:hover {
  background: #b8a07e;
}

.mb-30 {
  margin-bottom: 30px;
}

/*-----------------------------------------------------------------------------------*/
/*  HOMEPAGE Navbar
/*-----------------------------------------------------------------------------------*/

nav {
  position: fixed;
  background: #fff;
  z-index: 1000;
  width: 100%;
  padding: 20px;
  display: block;
  -webkit-box-shadow: 0px 2px 92px 0px rgba(0, 0, 0, 0.18);
  -moz-box-shadow: 0px 2px 92px 0px rgba(0, 0, 0, 0.18);
  box-shadow: 0px 2px 92px 0px rgba(0, 0, 0, 0.18);
  opacity: 0.8; 

}

.nav-menu {
  float: right;
  line-height: 32px;
  margin-bottom: 0;
  padding-top: 40px;
}

nav ul li {
  float: left;
  margin-right: 15px;
  font-family: 'Poppins', sans-serif;
  font-size: 14px;
  margin-left: 40px;
}

nav ul li a {
  padding: 2px 0 0 0;
  position: relative;
  display: block;
  font-size: 20px;
  color: #999999;
  font-family: 'Poppins', helvetica;
  font-weight: 500;
  line-height: 16px;
  text-transform: uppercase;
  letter-spacing: 0.02em;
  text-transform: capitalize;
}

.logo {
  float: left;
}

.logo img {
  width: 200px;
  height: 100px;
}

.responsive {
  display: none;
  font-size: 23px;
}

.active {
  color: #afafaf;
}

/*----------------------------------------------------------------------------------*/
/*  HOMEPAGE Header
/*-----------------------------------------------------------------------------------*/

#header {
  background: url("./images/home-bg.jpg") repeat scroll center center / cover;
  height: 100vh;
  width: 100%;
}

#header-project {
  height: 70vh;
  width: 100%;
}

#header-project .header-content {
 height: 70vh;
  text-align: left;
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

#header .header-content {
  height: 100vh;
  text-align: left;
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

#header-project .header-content h1 {
  font-size: 52px;
  font-weight: 700;
  margin-bottom: 10px;
  text-transform: uppercase;
  color: #fff;
}

#header .header-content h1 {
  font-size: 40px;
  font-weight: 700;
  margin-bottom: 10px;
  text-transform: uppercase;
  color: #fff;
}

.header-content p {
  font-size: 13px;
  letter-spacing: 5px;
  margin-top: 0;
  margin-bottom: 30px;
  text-transform: capitalize;
  color: #fff;
  font-weight: 500;
}

.header-content .list-social li {
  float: left;
  margin-right: 20px;
}

.header-content .list-social li i {
  color: #fff;
  font-size: 15px;
}

/*-----------------------------------------------------------------------------------*/
/*  HOMEPAGE About
/*-----------------------------------------------------------------------------------*/

#about {
  width: 100%;
  height: auto;
  background: #f7f7f7;
  padding-top: 150px;
}

#about .div-img-bg {
  padding-bottom: 30px;
  border: 20px solid #CCCCCC;
}

#about .div-img-bg .about-img img {
  width: 100%;
  box-shadow: 0px 0px 85px 0px rgba(0, 0, 0, 0.14);
  margin-top: -60px;
  margin-left: 40px;
  height: 400px;
  object-fit: cover;
}

#about .about-descr .p-heading {
  font-family: 'Playfair Display', serif;
  font-size: 28px;
  text-align: left;
}

#about .about-descr .separator {
  max-width: 80%;
  margin-bottom: 0;
  text-align: left;
}



/*-----------------------------------------------------------------------------------*/
/*  Project - Description
/*-----------------------------------------------------------------------------------*/

#project-descr {
  width: 100%;
  height: auto;
  background: #f7f7f7;
  padding-top: 100px;
}

#project-descr .div-img-bg img {
  width: 100%;
  margin-top: 100px;
  margin-bottom: auto;
  margin-left: auto;
  height: 100%;
  object-fit: cover;
}

#project-descr .description .heading {
  font-family: 'Playfair Display', serif;
  font-size: 45px;
  text-align: left;
  padding-bottom: 30px;
}

#project-descr .description .separator {
  max-width: 80%;
  margin-bottom: 0;
  text-align: left;
}



/*-----------------------------------------------------------------------------------*/
/*  Project - Presentation
/*-----------------------------------------------------------------------------------*/

#project-pres {
  width: 100%;
  height: auto;
  background: #fff;
  padding-top: 50px;
}

#project-pres .heading {
  font-family: 'Playfair Display', serif;
  font-size: 36px;
  text-align: center;
  padding-bottom: 50px;
}
  
/*Presentation - Demo iFrame styling*/

.embed-container { 
  position: relative; 
  padding-bottom: 56.25%; 
  height: 0; 
  overflow: hidden; 
  max-width: 100%; 
} 

.embed-container iframe, .embed-container object, .embed-container embed { 
  position: absolute; 
  top: 0; 
  left: 0; 
  width: 100%; 
  height: 100%; 
}

/*-----------------------------------------------------------------------------------*/
/*  Project - Demo
/*-----------------------------------------------------------------------------------*/

#project-demo {
  width: 100%;
  height: auto;
  background: #f7f7f7;
  padding-top: 50px;
}

#project-demo .heading {
  font-family: 'Playfair Display', serif;
  font-size: 36px;
  text-align: center;
  padding-bottom: 50px;
}


/*-----------------------------------------------------------------------------------*/
/*  HOMEPAGE Services
/*-----------------------------------------------------------------------------------*/

#services {
  background: #f7f7f7;
  position: relative;
  padding-top: 80px;
  padding-bottom: 80px;
}

.owl-item {
  float: left;
}
.services-block span {
  margin: 10px 0;
  position: relative;
  font-size: 13px;
  color: #292929;
  letter-spacing: 0.1em;
  font-weight: 500;
  text-transform: uppercase;
  display: block;
}

.services-block .separator {
  margin: 0;
  font-size: 13px;
  line-height: 22px;
}

.services-block i {
  font-size: 44px;
  color: #b8a07e;
  line-height: 44px;
}


.services-carousel  .owl-stage-outer {
  overflow: hidden;
}

.services-carousel .owl-dots {
  margin-top: 5px;
  text-align: center;
}

.services-carousel .owl-dot {
  display: inline-block;
  margin: 0 5px;
  width: 12px !important;
  height: 12px !important;
  border-radius: 50%;
  border: 0;
  padding: 0 !important;
  background-color: #ddd;
  cursor: pointer;
}

.services-carousel .owl-dot.active {
  background-color: #b8a07e;
}

.owl-nav {
  display: none;
}


/*-----------------------------------------------------------------------------------*/
/*  HOMEPAGE PROJECTS
/*-----------------------------------------------------------------------------------*/


#portfolio img:hover {
  -webkit-transform: scale(1.05);
	transform: scale(1.05);
	opacity: .5;
	
}

#portfolio img {
	
-webkit-transform: scale(1);
	transform: scale(1);
	-webkit-transition: .3s ease-in-out;
	transition: .3s ease-in-out;
	

}


#portfolio {
  position: relative;
  padding-bottom: 50px;
}

#portfolio .portfolio-list {
  margin-bottom: 50px;
}

#portfolio .portfolio-list .nav {
  display: inline-block;
  margin: 0;
}

#portfolio .portfolio-list .nav li {
  margin: 0 40px 0 0;
  float: left;
  font-size: 14px;
  color: #999999;
  line-height: 16px;
  cursor: pointer;
  font-family: 'Poppins', helvetica;
  font-weight: 500;
  letter-spacing: 0.02em;
  text-transform: capitalize;
  -moz-transition: all 0.5s ease-in-out 0s;
  -ms-transition: all 0.5s ease-in-out 0s;
  -o-transition: all 0.5s ease-in-out 0s;
  -webkit-transition: all 0.5s ease-in-out 0s;
  transition: all 0.5s ease-in-out 0s;
}

#portfolio .portfolio-list .nav li:hover, #portfolio .portfolio-list .nav li.filter-active  {
  color: #b8a07e;
  -moz-transition: all 0.5s ease-in-out 0s;
  -ms-transition: all 0.5s ease-in-out 0s;
  -o-transition: all 0.5s ease-in-out 0s;
  -webkit-transition: all 0.5s ease-in-out 0s;
  transition: all 0.5s ease-in-out 0s;
}

#portfolio .portfolio-container {
  display: inline-block;
  margin-bottom: 50px;
  width: 100%;
}

.portfolio-thumbnail {
  margin-bottom: 30px;
  overflow: hidden;
  min-height: 260px;
}

.portfolio-thumbnail img {
  max-width: 100%;
}

/*-----------------------------------------------------------------------------------*/
/*  HOMEPAGE Contact
/*-----------------------------------------------------------------------------------*/

#contact {
  height: auto;
  width: 100%;
}

#contact .row {
  align-items: center;
}

#contact .contact-img img {

  max-width: 100%;
  max-height: 100%;
  height: auto;

}

.contact-details {
  list-style: none;
  margin: 0;
  padding: 0;
}

.contact-details li {
  padding-bottom: 10px;
}


/*-----------------------------------------------------------------------------------*/
/*  Footer
/*-----------------------------------------------------------------------------------*/

#footer {
  background: #f7f7f7;
  padding-top: 50px;
  padding-bottom: 50px;
}

#footer .socials-media {
  width: 100%;
}

#footer .socials-media ul {
  display: inline-block;
  float: none;
  margin: 0 0 20px 0;
}

#footer .socials-media ul li {
  float: left;
  margin-left: 10px;
  margin-right: 10px;
}

#footer .socials-media ul li a {
  font-size: 24px;
  color: #999;
  letter-spacing: 0.1em;
  font-weight: 500;
  background: transparent;
  text-transform: uppercase;
  -moz-transition: all 0.5s ease-in-out 0s;
  -ms-transition: all 0.5s ease-in-out 0s;
  -o-transition: all 0.5s ease-in-out 0s;
  -webkit-transition: all 0.5s ease-in-out 0s;
  transition: all 0.5s ease-in-out 0s;
}

#footer .socials-media ul li a:hover {
  color: #b8a07e;
  -moz-transition: all 0.5s ease-in-out 0s;
  -ms-transition: all 0.5s ease-in-out 0s;
  -o-transition: all 0.5s ease-in-out 0s;
  -webkit-transition: all 0.5s ease-in-out 0s;
  transition: all 0.5s ease-in-out 0s;
}

#footer p {
  font-size: 12px;
  letter-spacing: 0.1em;
  font-weight: 500;
  margin-top: 0;
  text-transform: uppercase;
}

.credits {
  font-size: 13px;
}

/* Back to Top*/

.back-to-top {
  position: fixed;
  display: none;
  width: 40px;
  height: 40px;
  border-radius: 3px;
  right: 15px;
  bottom: 15px;
  background: #95A8BA;
  color: #fff;
  transition: display 0.5s ease-in-out;
  z-index: 99999;
}

.back-to-top i {
  font-size: 24px;
  position: absolute;
  top: 8px;
  left: 8px;
}

.back-to-top:hover {
  color: #fff;
  background: #C8C8C8;
  transition: background 0.2s ease-in-out;
}








